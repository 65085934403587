import {makeStyles} from '@material-ui/core'
import {graphql} from 'gatsby'
import React from 'react'
import {Heading, PageContainer} from '../components'
import {PageLayout} from '../layout'
import {SiteMetadata} from '../types'

export interface ImpressumPageProps {
  data: {
    site: {siteMetadata: SiteMetadata}
  }
}

const useStyles = makeStyles({
  pageLayout: {
    height: '100% !important',
    alignItems: 'center !important',
  },
})

export const ImpressumPage: React.FC<ImpressumPageProps> = ({data}) => {
  const {site} = data

  if (!site || !site.siteMetadata) {
    throw new Error(
      `Site metadata doesn't exist. Please fix it before opening this page.`,
    )
  }
  const classes = useStyles()

  return (
    <PageLayout
      title="Impressum"
      siteName={site.siteMetadata.title}
      description="Contact information of our company."
      lang="en"
      className={classes.pageLayout}
    >
      <PageContainer>
        <Heading variant="h2" component="h1">
          Impressum
        </Heading>

        <Heading variant="h3">Content coming soon.</Heading>
      </PageContainer>
    </PageLayout>
  )
}

export const query = graphql`
  query ImpressumPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ImpressumPage
